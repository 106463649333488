<template>
  <div class="contractLogisticsCmpt">
    <div class="facilityBox">
      <!-- 顶部按钮组 -->
      <div class="topbtns">
        <el-button
          type="primary"
          size="medium"
          :loading="flag.goWaybill"
          @click="goWaybill"
          >发往运单库</el-button
        >
      </div>

      <!-- 搜索框 -->
      <el-form ref="searchForm" inline :model="searchForm">
        <el-form-item label="运单状态" prop="waybillStatus">
          <el-select
            v-model="searchForm.waybillStatus"
            clearable
            placeholder="选择运单状态"
            @change="search"
          >
            <el-option
              v-for="item in waybillStatusList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="服务商单位" prop="facilitatorName">
          <el-input
            v-model="searchForm.facilitatorName"
            placeholder="请输入服务商单位"
          ></el-input>
        </el-form-item>
        <el-form-item label="运单号" prop="OrderID">
          <el-input
            v-model="searchForm.OrderID"
            placeholder="请输入运单号"
          ></el-input>
        </el-form-item>
        <el-button
          type="primary"
          icon="el-icon-zoom-in"
          :loading="waybillPagination.loading"
          @click="search"
          >搜索
        </el-button>
        <el-button
          icon="el-icon-delete"
          @click="$refs['searchForm'].resetFields()"
          >清空</el-button
        >
      </el-form>

      <!-- 表格信息 -->
      <el-table
        :data="waybillPagination.list"
        @selection-change="waybillSelectionChange"
        v-loading="waybillPagination.loading"
        :header-cell-style="{ color: '#666', background: '#f0f0f0' }"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column
          fixed="left"
          align="center"
          prop="index"
          label="序号"
          width="50"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="OrderID"
          label="运单号"
          width="200"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          prop="CompanyName"
          label="货主单位"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="FacilitatorName"
          label="服务商单位"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          fixed="right"
          align="center"
          prop=""
          label="任务完成状态"
          width="200"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-tag v-if="scope.row.Status == 0">新任务</el-tag>
            <el-tag v-if="scope.row.Status == 1">待服务商确认</el-tag>
            <el-tag v-if="scope.row.Status == 2">待司机确认</el-tag>
            <el-tag v-if="scope.row.Status == 3">服务商确认</el-tag>
            <el-tag type="danger" v-if="scope.row.Status == 4"
              >服务商拒绝</el-tag
            >
            <el-tag v-if="scope.row.Status == 5">司机确认</el-tag>
            <el-tag type="danger" v-if="scope.row.Status == 6">司机拒绝</el-tag>
            <el-tag v-if="scope.row.Status == 7">运输中</el-tag>
            <el-tag type="success" v-if="scope.row.Status == 8">已完成</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="DriverName"
          label="司机姓名"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="DriverPhone"
          label="司机手机号码"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="DriverIdCard"
          label="司机身份证号码"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="PayeeName"
          label="收款人姓名"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="PayeePhone"
          label="收款人手机号码"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="PayeeIdCard"
          label="收款人身份证号码"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="LeaderName"
          label="车队长姓名"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="LeaderPhone"
          label="车队长手机号码"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="LeaderIDCard"
          label="车队长身份证号码"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="CarNumber"
          label="车牌"
          width="200"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          prop="GoodsName"
          label="货品名称"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="GoodsUnitName"
          label="货品单位"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="GoodsValue"
          label="货品价值"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="CreateTime"
          label="任务创建时间"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="PlanStartDatetime"
          label="装货时间"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="PlanEndDatetime"
          label="签收时间"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="StartAddress"
          label="起始地"
          width="200"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div
              style="color:#0F5FFF; cursor: pointer"
              @click="openContractLogisticsMap(scope.row)"
            >
              {{ scope.row.StartAddress }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="EndAddress"
          label="到达地"
          width="200"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div
              style="color:#0F5FFF; cursor: pointer"
              @click="openContractLogisticsMap(scope.row)"
            >
              {{ scope.row.EndAddress }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="LoadCount"
          label="司机装货数量"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="driverCarryPrice"
          label="司机运输单价（人民币）"
          width="200"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div style="color:#FF8B17;">
              {{ scope.row.driverCarryPrice | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="CarrierPrice"
          label="运费"
          width="200"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div style="color:#FF8B17;">
              {{ scope.row.CarrierPrice | formatMoney }}
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        background
        style="margin-top: 16px;"
        @current-change="waybillPaginationChange"
        :current-page.sync="waybillPagination.page"
        :page-size="waybillPagination.pagesize"
        :total="waybillPagination.total"
        layout="total, prev, pager, next, jumper"
      >
      </el-pagination>

      <el-drawer
        size="100"
        :visible.sync="flag.contractLogisticsMap"
        append-to-body
      >
        <ContractWaybillTrace
          v-if="flag.contractLogisticsMap"
          :waybillInfo="waybillDetail"
          :pagetype="'boss'"
        >
        </ContractWaybillTrace>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import { getOrders, goWaybill } from "@/api/contractLogistics/plan";
import ContractWaybillTrace from "@/components/commonCmpt/contractWaybillTrace";
import { getOrderDetail } from "@/api/contractLogistics/contractWaybill";
export default {
  data() {
    return {
      flag: {
        goWaybill: false, //发往运单库中
        contractLogisticsMap: false, //合同物流轨迹地图
      },
      waybillPagination: {
        page: 1,
        pagesize: 10,
        total: 0,
        loading: false,
        list: [],
      },
      waybillSelectedList: [], //运单表格选中的数据
      searchForm: {
        waybillStatus: "", //运单状态
        facilitatorName: "", //服务商单位
        OrderID: "",
      },
      //运单状态列表
      waybillStatusList: [
        { value: "", label: "全部" },
        { value: 0, label: "新任务" },
        { value: 1, label: "待服务商确认" },
        { value: 2, label: "待司机确认" },
        { value: 3, label: "服务商确认" },
        { value: 4, label: "服务商拒绝" },
        { value: 5, label: "司机确认" },
        { value: 6, label: "司机拒绝" },
        { value: 7, label: "运输中" },
        { value: 8, label: "已完成" },
      ],
      currentWaybillItem: {}, //当前操作的表格对象
      waybillDetail: {}, //运单详情
    };
  },
  methods: {
    //搜索
    search() {
      this.waybillPagination.loading = true;
      let params = {
        pageIndex: this.waybillPagination.page,
        pageSize: this.waybillPagination.pagesize,
        status: this.searchForm.waybillStatus,
        facilitatorName: this.searchForm.facilitatorName,
        OrderID: this.searchForm.OrderID,
      };
      getOrders(params)
        .then((res) => {
          this.waybillPagination.list = res.data.DataList.map((item, index) => {
            item.index =
              (this.waybillPagination.page - 1) *
                this.waybillPagination.pagesize +
              index +
              1;
            item.driverCarryPrice = (
              item.CarrierPrice / item.LoadCount
            ).toFixed(2);
            return item;
          });
          this.waybillPagination.total = res.data.TotalCount;
        })
        .finally(() => {
          this.waybillPagination.loading = false;
        });
    },
    //获取运单列表
    getOrders() {
      this.waybillPagination.loading = true;
      let params = {
        pageIndex: this.waybillPagination.page,
        pageSize: this.waybillPagination.pagesize,
      };
      getOrders(params)
        .then((res) => {
          this.waybillPagination.list = res.data.DataList.map((item, index) => {
            item.index =
              (this.waybillPagination.page - 1) *
                this.waybillPagination.pagesize +
              index +
              1;
            item.driverCarryPrice = (
              item.CarrierPrice / item.LoadCount
            ).toFixed(2);
            return item;
          });
          this.waybillPagination.total = res.data.TotalCount;
        })
        .finally(() => {
          this.waybillPagination.loading = false;
        });
    },
    //运单表格分页
    waybillPaginationChange(e) {
      this.waybillPagination.page = e;
      this.waybillSelectedList = [];
      this.getOrders();
    },
    //运单表格选中
    waybillSelectionChange(e) {
      this.waybillSelectedList = e;
    },
    //发往运单库
    goWaybill() {
      if (this.waybillSelectedList.length === 0) {
        this.$message.error("请勾选运单后再点击“发往运单库”");
        return;
      }
      this.flag.goWaybill = true;
      let params = this.waybillSelectedList.map((item) => item.OrderID);
      goWaybill(params)
        .then((res) => {
          this.$message.success(`提交成功，批次号为${res.data}`);
          this.waybillSelectionChange(1);
        })
        .finally(() => {
          this.flag.goWaybill = false;
        });
    },
    //打开合同物流地图
    openContractLogisticsMap(e) {
      this.currentWaybillItem = e;
      this.waybillPagination.loading = true;
      let params = {
        orderId: e.OrderID,
      };
      getOrderDetail(params)
        .then((res) => {
          this.flag.contractLogisticsMap = true;
          this.$nextTick(() => {
            this.waybillDetail = res.data || {};
            this.waybillDetail.driverCarryPrice = (
              this.waybillDetail.CarrierPrice / this.waybillDetail.LoadCount
            ).toFixed(2);
          });
        })
        .finally(() => {
          this.waybillPagination.loading = false;
        });
    },
  },
  created() {
    this.getOrders();
  },
  components: {
    ContractWaybillTrace,
  },
};
</script>

<style lang="scss" scoped>
.topbtns {
  padding-bottom: 10px;
  margin: 0px 0px 10px;
  border-bottom: 1px solid #eee;
}
</style>
